import { useEffect, useRef, useState } from 'react';
import { Image, Linking, Pressable, View } from 'react-native';

import { NotificationData } from '../../../models/Feed.model';
import BATDialog from '../common/BATDialog';
import BATText from '../common/BATText';
import BATButtonNew from '../common/BATButtonNew';
import colors from '../../../config/theme/shared/colors';
import { sharedStyles } from '../../../config/theme/shared.styles';
import { pushGAEvent } from '../../../utils/gaUtils';

enum POPUPTYPE {
  POPUPWITHBACKGROUNDIMAGE = 'Image and Text Overlayed',
  POPUPWITHIMAGE = 'Image and Text Separated',
  POPUPWITHVIDEO = 'Video and Text Separated',
}

interface BATAnnouncementPopupProps {
  notifications: NotificationData[];
  handleSeenNotification: (id: string) => void;
}

const BATAnnouncementPopup = ({
  notifications,
  handleSeenNotification,
}: BATAnnouncementPopupProps) => {
  const [queue, setQueue] = useState<NotificationData[]>([]);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    setQueue(notifications);
  }, [notifications]);

  const handleEnded = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleOnClose = (action: 'button_click' | 'close') => {
    handleSeenNotification(queue[0].nid);
    const newQueue = [...queue];
    newQueue.shift();
    setQueue(newQueue);

    pushGAEvent('pop_up_interaction', 'details', {
      action,
    });
  };

  const handleOnPress = () => {
    if (queue[0].template.cta_link_external) Linking.openURL(queue[0].template.cta_link_external);
    handleOnClose('button_click');
  };

  const formatText = (text: string) => {
    return text.replaceAll(/<[^>]*>/g, '');
  };

  if (!queue.length) {
    return null;
  }

  return (
    <BATDialog
      visible={queue.length ? true : false}
      onDismiss={() => {
        handleOnClose('close');
      }}
      dismissable={!isPlaying}
      showCloseButton={
        queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHVIDEO ? !isPlaying : true
      }
      customStyles={[
        sharedStyles.widthFull,
        queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE && {
          backgroundImage: `url("${queue[0].template.image}")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
      ]}
      body={
        <Pressable
          style={[
            sharedStyles.flex,
            queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE && {
              aspectRatio: 1 / 1,
            },
            {
              cursor:
                queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE
                  ? 'pointer'
                  : 'default',
              height: 'auto',
            },
          ]}
          onPress={
            queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE
              ? handleOnPress
              : null
          }>
          {queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHVIDEO && (
            <View
              style={[sharedStyles.relative, sharedStyles.marginVertical, { aspectRatio: 1 / 1 }]}>
              <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                height="100%"
                width="100%"
                style={{ objectFit: 'cover', borderRadius: 16 }}
                onEnded={handleEnded}
                onPlay={handlePlay}>
                <source src={`${process.env.API_URL}${queue[0].template.video}`} type="video/mp4" />
              </video>
            </View>
          )}
          {queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHIMAGE &&
            queue[0].template.image && (
              <Image
                style={[{ width: '100%', aspectRatio: 16 / 9, borderRadius: 16, marginBottom: 8 }]}
                source={{ uri: queue[0].template.image }}
              />
            )}
          {queue[0].template.pop_up_type !== POPUPTYPE.POPUPWITHBACKGROUNDIMAGE && (
            <>
              {queue[0].template.title && (
                <BATText
                  type="heading-4"
                  color={colors.grey_title}
                  customStyle={sharedStyles.marginTop}>
                  {queue[0].template.title}
                </BATText>
              )}
              {queue[0].template.body && (
                <BATText type="body" color={colors.grey_subtitle}>
                  {formatText(queue[0].template.body)}
                </BATText>
              )}
            </>
          )}
        </Pressable>
      }
      bodyStyles={sharedStyles.paddingBottom}
      footer={
        queue[0].template.pop_up_type !== POPUPTYPE.POPUPWITHBACKGROUNDIMAGE &&
        queue[0].template.cta_text &&
        queue[0].template.cta_link_external ? (
          <View
            style={[
              sharedStyles.widthFull,
              sharedStyles.displayFlexWrap,
              queue[0].template.pop_up_type === POPUPTYPE.POPUPWITHBACKGROUNDIMAGE
                ? sharedStyles.justifyContentCenter
                : sharedStyles.justifyContentStart,
              sharedStyles.paddingBottom,
              sharedStyles.paddingHorizontalDouble,
            ]}>
            <BATButtonNew variant="contained" handleSubmitButton={handleOnPress}>
              {queue[0].template.cta_text}
            </BATButtonNew>
          </View>
        ) : undefined
      }
    />
  );
};

export default BATAnnouncementPopup;
