import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';
import { ScreenNames } from '../ScreenNames';

const Prizes = protectedLazyScreen(() => import('./Prizes'));
const PrizeView = protectedLazyScreen(() => import('./PrizeView'));
const RedeemCheckout = protectedLazyScreen(() => import('./RedeemCheckout'));
const MomentView = protectedLazyScreen(() => import('./MomentView'));
const BundleView = protectedLazyScreen(() => import('./BundleView'));

const Stack = createStackNavigator();

const PrizeStack = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={ScreenNames.prizeList}
      screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ScreenNames.prizeList}
        options={{ title: t('PRIZES_TITLE'), headerShown: false }}
        component={Prizes}
      />
    </Stack.Navigator>
  );
};

const PrizeFlow = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator
      initialRouteName={ScreenNames.prizeList}
      screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ScreenNames.prizeView}
        options={{ title: t('PRIZES_TITLE'), headerShown: false }}
        component={PrizeView}
      />
      <Stack.Screen
        name={ScreenNames.momentView}
        options={{ title: t('MOMENTS_TITLE'), headerShown: false }}
        component={MomentView}
      />
      <Stack.Screen
        name={ScreenNames.bundleView}
        options={{ title: t('PRIZES_TITLE'), headerShown: false }}
        component={BundleView}
      />
      <Stack.Screen
        name={ScreenNames.redeemCheckout}
        options={{ title: t('DELIVERY_ADDRESS'), headerShown: false }}
        component={RedeemCheckout}
      />
    </Stack.Navigator>
  );
};

export { PrizeStack, PrizeFlow };
