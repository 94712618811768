import api from '../config/api';
import Endpoints from '../config/api/endpoints';
import { UserDevice } from '../models/UserDevice.model';
import { urlEncode } from '../utils/urlUtils';

export const devicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.query<UserDevice[], void>({
      query: () => ({
        url: `${Endpoints.devices}`,
        method: 'GET',
      }),
    }),
    postDeviceAssign: builder.mutation({
      query: ({ body }) => ({
        url: `${Endpoints.deviceAssign}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        body: urlEncode(body),
      }),
    }),
    getDeviceVerify: builder.mutation({
      query: ({ params }) => ({
        url: `${Endpoints.deviceVerify}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDeviceVerifyMutation,
  useGetDevicesQuery,
  useLazyGetDevicesQuery,
  usePostDeviceAssignMutation,
} = devicesApi;
