import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { GetAllStoriesResponse } from '../../../models/Stories.model';
import { logoutUser } from '../../Authentication/slices/extraSlice';
import { createDraft } from 'immer';

type StoriesState = {
  stories: GetAllStoriesResponse[] | null;
};

const getInitialState = (): StoriesState => ({ stories: null });

type UpdateStoryFlagPayload = {
  bubbleIndex: number;
  storyIndex: number;
  flagName: 'like' | 'read';
  newValue: boolean;
};

const slice = createSlice({
  name: 'stories',
  initialState: getInitialState(),
  reducers: {
    resetStoriesState: (state) => {
      state.stories = null;
    },
    updateStoryFlag(state, action: PayloadAction<UpdateStoryFlagPayload>) {
      if (state.stories) {
        state.stories[action.payload.bubbleIndex].stories[action.payload.storyIndex][
          action.payload.flagName
        ] = action.payload.newValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getStories.matchFulfilled, (state, { payload }) => {
      state.stories = createDraft(payload as GetAllStoriesResponse[]);
      for (const bubble of state.stories) {
        for (const story of bubble.stories) {
          if (story.field_video_link !== '' && story.field_video_link != null) {
            story.duration = 60000;
          }
        }
      }
    });
  },
});

export const { resetStoriesState, updateStoryFlag } = slice.actions;

export default slice.reducer;
