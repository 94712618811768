import OneSignal from 'react-onesignal';
import { ENV, ONE_SIGNAL_APP_ID } from 'react-native-dotenv';

export const initOneSignal = async () => {
  if (ENV === 'localhost') {
    await OneSignal.init({ appId: ONE_SIGNAL_APP_ID, allowLocalhostAsSecureOrigin: true });
    OneSignal.Slidedown.promptPush();
  } else {
    OneSignal.init({ appId: ONE_SIGNAL_APP_ID });
  }
};
