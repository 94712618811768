import { StackScreenProps } from '@react-navigation/stack';
import React, { ComponentType, lazy, memo, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AuthNavigationContainer, { isAuthReady } from '../../../navigation/AuthNavigationContainer';
import { RootState } from '../../store';
import { BATLazyLoading } from './BATLazy';
import { Factory, screenFactory } from './screenFactory';

export const protectedLazyScreenAgeVerification = <T extends ComponentType<any>>(
  factory: Factory<T>,
) => {
  const Component = lazy(() => screenFactory(factory));

  return memo<StackScreenProps<any, string>>(function ProtectedLazyScreenAgeVerification(props) {
    const token = useSelector((state: RootState) => state.auth.token);
    const hideOnboarding = useSelector((state: RootState) => state?.auth?.userData?.hideOnboarding);

    const fallback = useMemo(() => <BATLazyLoading />, []);

    if (!isAuthReady(token, hideOnboarding)) {
      return <AuthNavigationContainer />;
    }

    return (
      <Suspense fallback={fallback}>
        {/* @ts-expect-error */}
        <Component {...props} />
      </Suspense>
    );
  });
};
