import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { anonymousLazyScreen } from '../../src/components/Lazy/anonymousLazyScreen';
import { ScreenNames } from '../ScreenNames';

const ForgotPassword = anonymousLazyScreen(() => import('./ForgotPassword'));
const GDPRCompliance = anonymousLazyScreen(() => import('./GDPRCompliance'));
const Login = anonymousLazyScreen(() => import('./Login'));
const ResetPassword = anonymousLazyScreen(() => import('./ResetPassword'));
const SignUp = anonymousLazyScreen(() => import('./SignUp'));
const VerificationCode = anonymousLazyScreen(() => import('./VerificationCode'));
const EmailRegisterConfirmation = anonymousLazyScreen(() => import('./EmailRegisterConfirmation'));
const SuccessRegistration = anonymousLazyScreen(() => import('./SuccessRegistration'));
const UserMigration = anonymousLazyScreen(() => import('./UserMigration'));
const OnBoarding = anonymousLazyScreen(() => import('../Onboarding/Onborading'));

const Stack = createStackNavigator();

export default React.memo(function AuthFlow() {
  const { t } = useTranslation();

  return (
    <Stack.Navigator initialRouteName={ScreenNames.login} screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ScreenNames.login}
        key={ScreenNames.login}
        options={{ title: t('LOGIN'), headerShown: false }}
        component={Login}
      />
      <Stack.Screen
        name={ScreenNames.userMigration}
        key={ScreenNames.userMigration}
        options={{ title: t('USER_MIGRATION'), headerShown: false }}
        component={UserMigration}
      />
      <Stack.Screen
        name={ScreenNames.signUp}
        key={ScreenNames.signUp}
        options={{ title: t('SIGN_UP'), headerShown: false }}
        component={SignUp}
      />
      <Stack.Screen
        name={ScreenNames.forgotPassword}
        key={ScreenNames.forgotPassword}
        options={{ title: t('FORGOT_PASSWORD'), headerShown: false }}
        component={ForgotPassword}
      />
      <Stack.Screen
        name={ScreenNames.verificationCode}
        key={ScreenNames.verificationCode}
        options={{ title: t('VERIFICATION_CODE'), headerShown: false }}
        component={VerificationCode}
      />
      <Stack.Screen
        name={ScreenNames.resetPassword}
        key={ScreenNames.resetPassword}
        options={{ title: t('RESET_PASSWORD'), headerShown: false }}
        component={ResetPassword}
      />
      <Stack.Screen
        name={ScreenNames.gdprCompliance}
        key={ScreenNames.gdprCompliance}
        options={{ title: t('GDPR_COMPLIANCE'), headerShown: false }}
        component={GDPRCompliance}
      />
      <Stack.Screen
        name={ScreenNames.emailRegisterConfirm}
        key={ScreenNames.emailRegisterConfirm}
        options={{ title: t('EMAIL_REGISTER_CONFIRMATION'), headerShown: false }}
        component={EmailRegisterConfirmation}
      />
      <Stack.Screen
        name={ScreenNames.successRegistration}
        key={ScreenNames.successRegistration}
        options={{ title: t('SUCCESS_REGISTRATION'), headerShown: false }}
        component={SuccessRegistration}
      />
      <Stack.Screen
        name={ScreenNames.onBoarding}
        options={{ title: t('ONBOARDING'), headerShown: false }}
        component={OnBoarding}
      />
    </Stack.Navigator>
  );
});
