const Endpoints = {
  userIdentify: 'api/user-identify',
  login: 'oauth/token',
  validateReferralLink: 'api/referral-validate',
  migrationLogin: 'api/consumers-authenticate',
  migrateUser: 'api/consumers-migrate',
  register: 'api/consumers',
  customersValidate: 'api/consumers-validate',
  getHomeFeed: 'api/home-feed',
  getUserPoints: '/api/user-points',
  profileCompletionPoints: '/api/points-value/profile_completion',
  surveyCompletionPoints: '/api/points-value/completing_survey',
  contactUs: '/api/create-case',
  getArticles: 'api/collection/articles',
  getArticle: 'api/article/details',
  getAddress: 'api/address',
  getProfileAddress: 'api/salesforce/address',
  getRelatedArticlesSimple: 'api/collection/related-articles',
  getRelatedArticlesProducts: 'api/collection/related-articles-products',
  getProductRanges: 'api/taxonomy/product-ranges',
  getProducts: 'api/collection/products',
  getProductDetails: 'api/product/details',
  getBrands: 'api/taxonomy/brands',
  getRelatedProducts: 'api/products-reco',
  account: 'api/account',
  getRelatedArticlesByProductRange: 'api/collection/related-articles-range',
  getRelatedArticlesByBrandId: 'api/collection/related-articles-brand',
  getPromos: 'api/collection/promos',
  consumePackCode: 'api/code/consume',
  articleEarnPoints: 'api/article-read',
  getHeroBannerByProductRange: 'api/article-product/last',
  getSurveysCard: 'api/surveys',
  validateEmail: 'api/user-token-generate',
  forgotPassword: '/api/user-profile/password-reset',
  forgotPasswordByPhone: '/api/user-profile/password-reset-phone',
  validateSMS: '/api/sms-generate',
  inviteFriend: '/api/referral',
  getWebform: '/webform_rest/{{id}}/elements?_format=json',
  postWebformSubmit: '/webform_rest/submit',
  getLuckyMoments: 'api/lucky-moments',
  notification: 'api/user-notifications',
  getHotDeals: 'api/hot-deals',
  sendSMSCode: 'api/consumers-generate',
  loginValidateEmail: 'api/consumers/validate',
  getPDFs: '/api/pdfs',
  stories: 'api/stories',
  storyStatus: 'api/story-status',
  flagStories: 'api/stories-flag',
  storySubmissions: 'api/submission-results',
  patchOnBoardingStatus: 'api/user-profile/onboarding',
  getCampaignsList: 'api/collection/campaigns',
  getCampaignDetails: 'api/campaign/details',
  getCampaignGallery: 'api/campaign',
  flagCampaign: 'api/campaign-submission-flag',
  devices: '/api/user-devices',
  deviceAssign: '/api/devices/assign',
  deviceVerify: '/api/devices/verify',
  accountsLimitReached: '/api/accounts-limit-reached',
  staticFilePages: '/api/static-file-pages',
  staticFileId: '/api/static-files',
  yotiValidation: '/api/yoti-validation',
  yotiValidationIDScanSession: '/api/yoti-validation/sessions',
  yotiUserUpdate: '/api/yoti-user',
  getReCaptchaValidationStatus: '/api/recaptcha/status',
  favorites: '/api/pages/favorites',
  getSearch: '/api/search',
  collections: '/api/home/collections',
  authors: '/api/taxonomy/authors',
  authorArticles: '/api/collection/authors',
  getContentTerritories: 'api/taxonomy/content-territories',
  getSearchPromotedPosts: 'api/promoted',
  getCustomQuizzTaxonomyItems: '/api/custom-quizz/{{name}}/all',
  customQuizz: '/api/bato-quizz',
  getCampaignsActivitiesList: '/api/collection/campaigns/activities',
};

export default Endpoints;
