import { createSlice } from '@reduxjs/toolkit';
import api from '../../../config/api';
import { logoutUser } from '../../Authentication/slices/extraSlice';

type ContentState = {
  brandProduct: {
    activeBrand: string | null;
    brandList: any[] | null;
  };
  brandPrizes: {
    activeBrand: any | null;
    brandList: any[] | null;
  };
};

const getInitialState = (): ContentState => ({
  brandProduct: { activeBrand: null, brandList: null },
  brandPrizes: { activeBrand: null, brandList: null },
});

const slice = createSlice({
  name: 'content',
  initialState: getInitialState(),
  reducers: {
    changeActiveProductBrand: (state, action) => {
      state.brandProduct = {
        ...state.brandProduct,
        activeBrand: action.payload.tid,
      };
    },
    changeActivePrizeBrand: (state, action) => {
      state.brandPrizes = {
        ...state.brandPrizes,
        activeBrand: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser, (state) => {
      return getInitialState();
    });
    builder.addMatcher(api.endpoints.getBrands.matchFulfilled, (state, { payload }) => {
      if (state.brandPrizes.activeBrand === null) {
        const defaultBrand = payload.find((brand) => brand.default);
        const gloBrand = payload.find((brand) => brand.name === 'glo');
        state.brandPrizes = {
          brandList: payload,
          activeBrand: defaultBrand || gloBrand,
        };
      }
    });
    builder.addMatcher(api.endpoints.getProductRages.matchFulfilled, (state, { payload }) => {
      if (state.brandProduct.activeBrand === null) {
        state.brandProduct = {
          brandList: payload,
          activeBrand: payload[0].tid,
        };
      }
    });
  },
});

export const { changeBrandName, changeActiveProductBrand, changeActivePrizeBrand } = slice.actions;

export default slice.reducer;
