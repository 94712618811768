import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';
import { ScreenNames } from '../ScreenNames';

const Products = protectedLazyScreen(() => import('./Products'));
const ProductCategories = protectedLazyScreen(() => import('./ProductCategories'));
const ProductDetails = protectedLazyScreen(() => import('./ProductDetails'));
const KentModeInfinite2 = protectedLazyScreen(() => import('./KentModeInfinite2'));

const Stack = createStackNavigator();

const ProductsStack = () => {
  const { t } = useTranslation();

  return (
    <Stack.Navigator initialRouteName={ScreenNames.products} screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name={ScreenNames.products}
        key={ScreenNames.products}
        options={{ title: t('PRODUCTS_TITLE') }}
        component={Products}
      />
      <Stack.Screen
        name={ScreenNames.productCategories}
        key={ScreenNames.productCategories}
        options={{ title: t('PRODUCT_CATEGORIES') }}
        component={ProductCategories}
      />
      <Stack.Screen
        name={ScreenNames.productDetails}
        options={{ title: t('PRODUCT_DETAILS') }}
        component={ProductDetails}
      />
      <Stack.Screen
        name={ScreenNames.kentModeInfinite2}
        key={ScreenNames.kentModeInfinite2}
        options={{ title: t('PRODUCTS_TITLE'), headerShown: false }}
        component={KentModeInfinite2}
      />
    </Stack.Navigator>
  );
};

export { ProductsStack };
