import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ScreenNames } from '../ScreenNames';
import { useTranslation } from 'react-i18next';
import { protectedLazyScreen } from '../../src/components/Lazy/protectedLazyScreen';

const Profile = protectedLazyScreen(() => import('./Profile'));
const PersonalDetails = protectedLazyScreen(() => import('./PersonalDetails'));
const Devices = protectedLazyScreen(() => import('./Devices'));
const InviteFriend = protectedLazyScreen(() => import('./InviteFriend'));
const Privacy = protectedLazyScreen(() => import('./Privacy'));
const Help = protectedLazyScreen(() => import('./Help'));
const ContactUs = protectedLazyScreen(() => import('./ContactUs'));
const FAQ = protectedLazyScreen(() => import('./FAQ'));
const Cookies = protectedLazyScreen(() => import('./Cookies'));
const HelpfulInformation = protectedLazyScreen(() => import('./HelpfulInformation'));
const ChangePassword = protectedLazyScreen(() => import('./ChangePassword'));

const Stack = createStackNavigator();

const ProfileFlow = () => {
  const { t } = useTranslation();
  return (
    <Stack.Navigator initialRouteName={ScreenNames.profile}>
      <Stack.Screen
        name={ScreenNames.profile}
        key={ScreenNames.profile}
        options={{ title: t('PROFILE_TITLE'), headerShown: false }}
        component={Profile}
      />
      <Stack.Screen
        name={ScreenNames.personalDetails}
        key={ScreenNames.personalDetails}
        options={{ title: t('PERSONAL_DETAILS'), headerShown: false }}
        component={PersonalDetails}
      />
      <Stack.Screen
        name={ScreenNames.devices}
        key={ScreenNames.devices}
        options={{ title: t('DEVICES'), headerShown: false }}
        component={Devices}
      />
      <Stack.Screen
        name={ScreenNames.privacy}
        key={ScreenNames.privacy}
        options={{ title: t('PRIVACY'), headerShown: false }}
        component={Privacy}
      />
      <Stack.Screen
        name={ScreenNames.inviteFriend}
        key={ScreenNames.inviteFriend}
        options={{ title: t('INVITE_A_FRIEND'), headerShown: false }}
        component={InviteFriend}
      />
      <Stack.Screen
        name={ScreenNames.help}
        key={ScreenNames.help}
        options={{ title: t('HELP'), headerShown: false }}
        component={Help}
      />
      <Stack.Screen
        name={ScreenNames.contactUs}
        key={ScreenNames.contactUs}
        options={{ title: t('CONTACT_US'), headerShown: false }}
        component={ContactUs}
      />
      <Stack.Screen
        name={ScreenNames.faq}
        key={ScreenNames.faq}
        options={{ title: t('FAQ'), headerShown: false }}
        component={FAQ}
      />
      <Stack.Screen
        name={ScreenNames.cookiesSettings}
        key={ScreenNames.cookiesSettings}
        options={{ title: t('COOKIES'), headerShown: false }}
        component={Cookies}
      />
      <Stack.Screen
        name={ScreenNames.helpfulInformation}
        key={ScreenNames.helpfulInformation}
        options={{ title: t('HELPFUL_INFORMATION'), headerShown: false }}
        component={HelpfulInformation}
      />
      <Stack.Screen
        name={ScreenNames.changePassword}
        key={ScreenNames.changePassword}
        options={{ title: t('CHANGE_PASSWORD'), headerShown: false }}
        component={ChangePassword}
      />
    </Stack.Navigator>
  );
};

export default ProfileFlow;
