import Constants from '../config/Constants';

export const removeWhitespace = (str: string): string => {
  return str.replace(new RegExp(Constants.spacesRegex, 'gim'), '');
};

export const isNullOrEmptyString = (str: string | null | undefined) => {
  return str === '' || str == null;
};

export const trimAndLowerCase = (str: string | null | undefined) => {
  if (str == null) return str;
  return str.toLocaleLowerCase?.()?.trim();
};
