export const brandDetails = [
  {
    name: 'glo',
    color: '#E8603E',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/glo-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/glo-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/glo-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/glo-middle-bottom-left.png'),
  },
  {
    name: 'Vuse',
    color: '#AE4283',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/vuse-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/vuse-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/vuse-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/vuse-middle-bottom-left.png'),
  },
  {
    name: 'Dunhill',
    color: '#BAA066',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/dunhill-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/dunhill-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/dunhill-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/dunhill-middle-bottom-left.png'),
  },
  {
    name: 'Kent',
    color: '#2B2BFD',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/kent-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/kent-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/kent-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/kent-middle-bottom-left.png'),
  },
  {
    name: 'Pall Mall',
    color: '#4AA8FF',
    topPersonalization: require('../assets/filters-logo-brand-personalizations/pall-mall-top.png'),
    bottomPersonalization: require('../assets/filters-logo-brand-personalizations/pall-mall-bottom.png'),
    middleTopRightUrl: require('../assets/filters-logo-brand-personalizations/pall-mall-middle-top-right.png'),
    middleBottomLeftUrl: require('../assets/filters-logo-brand-personalizations/pall-mall-middle-bottom-left.png'),
  },
];

export const orderBrands = (brands: string[]) => {
  brands.sort((a, b) => {
    return (
      brandDetails.findIndex((brand) => brand.name === a) -
      brandDetails.findIndex((brand) => brand.name === b)
    );
  });

  return brands;
};

export const transformFromUrlIntoBrandName = (brandUrl: string) => {
  const formattedUrl = brandUrl.toLowerCase().trim().replaceAll('-', ' ');
  const matchedBrand = brandDetails.find((brand) =>
    formattedUrl.includes(brand.name.toLowerCase()),
  );
  return matchedBrand?.name || '';
};
