import {
  Brands,
  HeroBanner,
  Product,
  ProductRange,
  ProductRanges,
} from './../models/Products.model';
import api from '../config/api';
import Endpoints from '../config/api/endpoints';

export const productsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProductRages: builder.mutation<ProductRanges[], any>({
      query: () => ({
        url: Endpoints.getProductRanges,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProductRagesFromParent: builder.mutation<ProductRange[], any>({
      query: ({ tid }) => ({
        url: `${Endpoints.getProductRanges}/${tid}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProductsFromRange: builder.mutation<Product[], any>({
      query: ({ categorySlug }) => ({
        url: `${Endpoints.getProducts}/${categorySlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getBrands: builder.mutation<Brands[], any>({
      query: () => ({
        url: Endpoints.getBrands,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getRelatedProducts: builder.mutation<ProductRange[], any>({
      query: () => ({
        url: Endpoints.getRelatedProducts,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getHeroBannerByProductRange: builder.mutation<HeroBanner, any>({
      query: () => ({
        url: Endpoints.getHeroBannerByProductRange,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
    getProductDetails: builder.mutation<any, any>({
      query: (productSlug) => ({
        url: `${Endpoints.getProductDetails}/${productSlug}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductRagesMutation,
  useGetProductRagesFromParentMutation,
  useGetProductsFromRangeMutation,
  useGetBrandsMutation,
  useGetRelatedProductsMutation,
  useGetHeroBannerByProductRangeMutation,
  useGetProductDetailsMutation,
} = productsApi;
